@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

* {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
  margin: 0 !important;
  padding: 0;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  text-shadow: 1px 1px 1px grey;
}

#main {
  position: absolute;
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: #212529 !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: small;
  justify-content: space-between;
  position: absolute;


}

.bottom {
  bottom: 0;
  left: 0;
}

.top {
  top: 0;
  left: 0;
}

.smaller {
  height: 76px !important;
  bottom: 4px !important;
  width: 99% !important;
  left: 0.5% !important;
}

#desktop {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#main {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

#desktop,
#settings {
  width: 100%;
  height: calc(100vh - 84px);
}

.parent-container {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

}



#navLeft,
#navRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#googleSearch label {
  padding-right: 20px;
}

#googleSearch button {
  margin-left: 20px !important;
}

.nes-input,
.nes-textarea {
  border-image-repeat: repeat !important;
}

#clock {
  padding: 10px
}

.icon {
  margin-top: 10px !important;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.icon-selected {
  background-color: rgba(120, 104, 104, 0.397);
  border-radius: 5px;
  clip-path: polygon(0px calc(100% - 16px),
      4px calc(100% - 16px),
      4px calc(100% - 12px),
      8px calc(100% - 12px),
      8px calc(100% - 8px),
      12px calc(100% - 8px),
      12px calc(100% - 4px),
      16px calc(100% - 4px),
      16px 100%,
      calc(100% - 16px) 100%,
      calc(100% - 16px) calc(100% - 4px),
      calc(100% - 12px) calc(100% - 4px),
      calc(100% - 12px) calc(100% - 8px),
      calc(100% - 8px) calc(100% - 8px),
      calc(100% - 8px) calc(100% - 12px),
      calc(100% - 4px) calc(100% - 12px),
      calc(100% - 4px) calc(100% - 16px),
      100% calc(100% - 16px),
      100% 16px,
      calc(100% - 4px) 16px,
      calc(100% - 4px) 12px,
      calc(100% - 8px) 12px,
      calc(100% - 8px) 8px,
      calc(100% - 12px) 8px,
      calc(100% - 12px) 4px,
      calc(100% - 16px) 4px,
      calc(100% - 16px) 0px,
      16px 0px,
      16px 4px,
      12px 4px,
      12px 8px,
      8px 8px,
      8px 12px,
      4px 12px,
      4px 16px,
      0px 16px);
}


.window-navbar {
  position: static;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(1, 8, 77);
  width: 100%;
  height: 40px;
  text-align: center;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0, pointer;
}

.resizeable {
  width: 30px;
  height: 30px;
  background-color: rgb(29, 33, 84);
  position: absolute;
  bottom: 0;
  right: 0;
  border: 4px solid rgb(255, 255, 255);
}

.window-navbar-left {
  margin-left: 24px !important;
}

.window-navbar-right {
  margin-right: 9px !important;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

#projects {
  align-self: center;
}


.draggable {
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 600px;
  min-height: 500px;
  width: 600px;
  height: 500px;
  background-color: #212429;
  border: 1px solid #dedede;
  resize: both;
  overflow: auto;
  border: 4px solid white;
  overflow: hidden;
}

.draggable-panel {
  padding: 10px;
  background-color: #eee;
  color: #162333;
  position: static;

}

.draggable-content {

  width: 100%;
  height: 100%;
}

.active-apps {
  display: flex;
  flex-direction: row;
  margin-left: 20px !important;
  gap: 5px;
}

.active {
  background-color: rgb(227, 227, 227);

}

#browser {
  height: 100%;
  overflow: hidden;

}

#browser-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 100px
}

#browser-container {
  height: 100%;
  width: 100%;

}

.browser-navigation-right {
  width: 80%;
}

.react-terminal-window-buttons {
  display: none !important;
}

.react-terminal-wrapper {
  font-size: 14px !important;
  height: 95% !important;
  position: absolute !important;
  padding: 0 0 0 0 !important;
}

.react-terminal {
  max-width: 100%;
  /* Maximum width is the full width of the parent */
  max-height: 100%;
  /* Maximum height is the full height of the parent */
  position: absolute;
  /* If you want it positioned inside the wrapper */
  height: 100% !important;
  overflow: auto !important;
}

.react-terminal::-webkit-scrollbar {
  display: none;
}





.setting {
  box-sizing: border-box;
  float: left;
  width: 50%;
  height: 350px;

}

.setting>h3 {
  text-align: center;
  margin-bottom: 10px !important;
}

.setting>div>input,
.setting>div>button {
  text-align: center;
  margin-bottom: 16px !important;
}

#backgroundPreviewContainer {
  width: 50%;
  height: auto;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  margin-top: 8px;
  margin-right: 8px !important;
  top: 4px !important;
  height: 150px
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  width: 50%;
  height: 90%;
}

.modal-close {
  float: right;
  margin-right: 8px;
  margin-top: 8px;
}

.modal-body p {
  text-align: center;
}

.gallery-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
  gap: 8px;
}

.gallery-image{
  width: 100%;
}


.no-padding {
  padding: 0 !important;
}

.buttons-container {
  right: 0px
}

.miscSettings {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.miscSetting {
  display: flex;
  flex-direction: column;


}


.is-light {
  background-color: #ddd;
  /* Light background */
  color: #333;
  /* Dark text color for contrast */

}

.icon-light {
  filter: invert(1) hue-rotate(180deg);

}

.icon-dark {
  filter: invert(0) hue-rotate(0deg);
}

.terminal-output-line {
  display: inline;
  white-space: nowrap;
}

.nes-icon {
  margin-right: 32px !important;
  margin-bottom: 32px !important;
}

.icon-list{
  gap:26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.links-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}



.pulse {
  animation: pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.loadable {
  border-radius: 1.25rem;

  margin: 0 auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}


@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}


.filesContainer{
  padding: 20px ;
}


.text-editor textarea{
  overflow: auto;
  padding: 10px;
  background-color: #ffffff00;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  resize: none;
}


.white-text{
  color: white;
}

.text-editor-buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.text-editor{
  width: 100%;
  height: calc(100% - 158px);

}

.text-editor-input{
  width: 100%;
  height: 100%;
 
}


textarea{
  display: block;
  margin-left: auto;
  margin-right: auto;
 
}